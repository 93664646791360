import { Image } from "@ludens-reklame/rubics-v3-sdk/file/types";
import { transformImageUrl } from "@ludens-reklame/rubics-v3-sdk/file/utils";
import { Link } from "@ludens-reklame/rubics-v3-sdk/link/types";
import { getLinkProps } from "../../client-utils/getLinkProps.js";
import { RichText } from "../../internal-components/richText/RichText.js";
import { arrow } from "../../icons/svg.js";

interface Props {
  text?: string;
  image?: Image;
  link?: Link;
}

const CtaBox: React.FC<Props> = ({ text, image, link }) => {
  return (
    <div className="cta-box hs vs-xl mw">
      <div
        className="container"
        style={
          image
            ? {
                backgroundImage: `url(${transformImageUrl(image, {
                  width: 1080,
                })})`,
              }
            : undefined
        }
      >
        {text && (
          <div className="box box-desktop">
            <RichText html={text} />
            {link && (
              <a {...getLinkProps(link)} className="button tertiary">
                {link.title} {arrow}
              </a>
            )}
          </div>
        )}
      </div>
      {text && (
        <div className="box box-mobile">
          <RichText html={text} />
          {link && (
            <a {...getLinkProps(link)} className="button tertiary">
              {link.title} {arrow}
            </a>
          )}
        </div>
      )}
    </div>
  );
};

export default CtaBox;
